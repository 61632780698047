<template>
	<section class="speakers">
		<header class="speakers__header">
			<h2 class="speakers__page-title is-title is-inverted">{{ headerData ? headerData.title : 'Speakers' }}</h2>
			<div class="speakers__page-img page-background" :style="{ backgroundImage: backgroundUrl }"></div>
			<div class="page-description">{{ headerData ? headerData.subtitle : '' }}</div>
		</header>
		<main class="speakers__main article article-content">
			<div class="container">
				<template v-if="isLoading">
					<div class="speakers__content-loading">
						<inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg>
						<h3>Loading...</h3>
					</div>
				</template>
				<template v-if="!isLoading && speakers.length === 0">
					<div class="speakers__no-content">
						<inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg>
						<h3>There are no speakers.</h3>
					</div>
				</template>
				<template v-if="!isLoading && speakers.length > 0">
					<div class="speakers__search">
						<div class="control has-icons-right">
							<span class="icon is-right">
								<inline-svg :src="require('../assets/search.svg')" width="28"></inline-svg>
							</span>
							<input v-model="searchedString" class="input" type="text" placeholder="Start typing to search speakers" @keyup="filterByText" />
						</div>
						<button class="speakers__action-button" :class="{ 'is-active': isAlphabetSorted }" @click.prevent="toggleSort">
							<inline-svg :src="require('../assets/sort-alpha.svg')" width="28"></inline-svg>
						</button>
						<button class="speakers__action-button" :class="{ 'is-active': activeFilters }" @click.prevent="toggleMore">
							<inline-svg :src="require('../assets/filter.svg')" width="28"></inline-svg>
							<span v-if="activeFilters" class="filter-counter">{{ activeFilters }}</span>
						</button>
						<div class="speakers__search__more" :class="{ 'is-open': searchMore }">
							<div class="columns is-multiline">
								<div class="column is-full">
									<div class="field">
										<label class="label">Country</label>
										<div class="control">
											<v-select v-model="selectedCountries" multiple :options="countries" label="name"></v-select>
										</div>
									</div>
								</div>
								<div class="column is-full has-text-right speakers__search__buttons">
									<a class="button is-primary is-outlined" @click="resetFilters(true)">Clear All</a>
									<a class="button is-primary" @click="toggleMore(true)">Apply</a>
								</div>
							</div>
						</div>
					</div>
					<div class="speakers__list">
						<router-link v-for="speaker in filteredSpeakers" :key="speaker.id" :to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }" class="speakers__item">
							<div class="speakers__item-image-wrapper">
								<img
									v-if="speaker.profile_picture"
									:src="speaker.profile_picture | imageUrl"
									:alt="`${speaker.firstName} ${speaker.lastName} photo`"
									class="speakers__item-image"
								/>
								<img v-else src="@/assets/placeholder_speaker.jpg" :alt="`${speaker.firstName} ${speaker.lastName} photo`" class="speakers__item-image" />
							</div>
							<div class="speakers__item-info">
								<div class="speakers__item-meta">
									<h3 class="speakers__item-title">
										<strong>{{ speaker.firstName }} {{ speaker.lastName }}</strong>
									</h3>
									<p class="speakers__item-country">{{ speaker.country }}</p>
								</div>
								<div class="speakers__item-footer">
									<div class="speakers__item-more">
										<strong>Read more</strong>
									</div>
									<div v-if="speaker.social.length > 0" class="speakers__item-social">
										<a v-for="social in speaker.social[0]['social_item']" :key="social.id" :href="social.url" target="_blank" class="speakers__item-social-link">
											<inline-svg v-if="social.type" :src="require(`../assets/${social.type}.svg`)" width="16"></inline-svg>
										</a>
									</div>
								</div>
							</div>
						</router-link>
						<div v-if="filteredSpeakers.length === 0" class="speakers__no-content">
							<inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg>
							<h3>There are no speakers.</h3>
						</div>
					</div>
				</template>
			</div>
		</main>
	</section>
</template>

<script>
	import $http from '../utils/http.js';

	export default {
		name: 'Speakers',
		data() {
			return {
				headerData: null,
				searchedString: '',
				speakers: [],
				filteredSpeakers: [],
				countries: [],
				selectedCountries: [],
				searchMore: false,
				isAlphabetSorted: true,
				isLoading: true,
				lastActiveFilters: {
					selectedCountries: [],
				},
			};
		},
		computed: {
			backgroundUrl() {
				return this.headerData && this.headerData.background ? `url('${this.$options.filters.imageUrl(this.headerData.background)}')` : null;
			},
			activeFilters() {
				let c = 0;
				if (this.selectedCountries.length > 0) c++;
				return c;
			},
		},
		mounted() {
			this.getHeaderData();
			this.getCountries();
			this.getSpeakers();
		},
		methods: {
			getHeaderData() {
				$http.get('/page-speakers').then(({ data }) => {
					this.headerData = data;
				});
			},
			getSpeakers() {
				$http.get('/speakers?_limit=-1').then(({ data }) => {
					this.speakers = data;
					this.filterByText();
					this.alphabetSort(this.filteredSpeakers);
					this.isLoading = false;
				});
			},
			getCountries() {
				$http.get('/countries?_limit=300').then(({ data }) => {
					this.countries = data;
				});
			},
			toggleMore(search) {
				this.searchMore = !this.searchMore;

				if (search === true) {
					this.copyActiveFilters();
					this.filterByCountries();
				}

				this.pasteActiveFilters();

				if (!this.activeFilters) this.resetFilters();
			},
			pasteActiveFilters() {
				this.selectedCountries = this.lastActiveFilters.selectedCountries;
			},
			copyActiveFilters() {
				this.lastActiveFilters.selectedCountries = this.selectedCountries;
			},
			resetFilters(apply) {
				this.selectedCountries = [];

				if (apply) {
					this.searchMore = !this.searchMore;
					this.copyActiveFilters();
					this.filterByCountries();
					this.pasteActiveFilters();
				}
			},
			filterByText() {
				let searchedSource;

				if (this.selectedCountries.length > 0) {
					this.filterByCountries();
					searchedSource = this.filteredSpeakers;
				} else {
					searchedSource = this.speakers;
				}

				this.filteredSpeakers = searchedSource.filter(item => {
					const normalizedItemName =
						item.firstName +
						' ' +
						item.lastName
							.normalize('NFD')
							.replace(/\p{Diacritic}/gu, '')
							.toLowerCase();
					const normalizedSearchedString = this.searchedString
						.normalize('NFD')
						.replace(/\p{Diacritic}/gu, '')
						.toLowerCase();

					return normalizedItemName.includes(normalizedSearchedString);
				});

				this.alphabetSort(this.filteredSpeakers);
			},
			filterByCountries() {
				let searchedSource;

				if (this.searchedString) {
					searchedSource = this.filteredSpeakers;
				} else {
					searchedSource = this.speakers;
				}

				if (this.selectedCountries.length > 0) {
					this.filteredSpeakers = searchedSource.filter(item => {
						let isSame = false;

						const normalizedItemCountry = item.country
							.normalize('NFD')
							.replace(/\p{Diacritic}/gu, '')
							.toLowerCase();
						this.selectedCountries.forEach(item => {
							let normalizedSearchedCountry = item.name
								.normalize('NFD')
								.replace(/\p{Diacritic}/gu, '')
								.toLowerCase();

							if (normalizedSearchedCountry === normalizedItemCountry) {
								isSame = true;
							}
						});

						if (isSame) {
							return true;
						}
					});
				} else {
					this.filteredSpeakers = this.speakers;
				}

				this.alphabetSort(this.filteredSpeakers);
			},
			alphabetSort(array, descending = false) {
				descending ? (this.isAlphabetSorted = false) : (this.isAlphabetSorted = true);

				array.sort((a, b) => {
					if (a.lastName && b.lastName) {
						if (descending) {
							return a.lastName.localeCompare(b.lastName) * -1;
						} else {
							return a.lastName.localeCompare(b.lastName);
						}
					}

					return 0;
				});
			},
			toggleSort() {
				if (this.isAlphabetSorted) {
					this.alphabetSort(this.filteredSpeakers, true);
				} else {
					this.alphabetSort(this.filteredSpeakers);
				}
			},
		},
	};
</script>
